import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  FACEBOOK_LOGIN_USER,
  FACEBOOK_LOGIN_USER_SUCCESS,
  FACEBOOK_LOGIN_USER_ERROR,
  GOOGLE_LOGIN_USER,
  GOOGLE_LOGIN_USER_SUCCESS,
  GOOGLE_LOGIN_USER_ERROR,
  APPLE_LOGIN_USER,
  APPLE_LOGIN_USER_SUCCESS,
  APPLE_LOGIN_USER_ERROR,
  UPDATE_COIN
} from '../actions';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});
export const loginUserSuccess = (user,userInfo) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});

export const facebookLoginUser = (history) => ({
  type: FACEBOOK_LOGIN_USER,
  payload: { history }
});
export const facebookLoginUserSuccess = (user) => ({
  type: FACEBOOK_LOGIN_USER_SUCCESS,
  payload: user
});
export const facebookLoginUserError = (message) => ({
  type: FACEBOOK_LOGIN_USER_ERROR,
  payload: { message }
});
export const googleLoginUser = (history) => ({
  type: GOOGLE_LOGIN_USER,
  payload: { history }
});
export const googleLoginUserSuccess = (user) => ({
  type: GOOGLE_LOGIN_USER_SUCCESS,
  payload: user
});
export const googleLoginUserError = (message) => ({
  type: GOOGLE_LOGIN_USER_ERROR,
  payload: { message }
});
export const appleLoginUser = (history) => ({
  type: APPLE_LOGIN_USER,
  payload: { history }
});
export const appleLoginUserSuccess = (user) => ({
  type: APPLE_LOGIN_USER_SUCCESS,
  payload: user
});
export const appleLoginUserError = (message) => ({
  type: APPLE_LOGIN_USER_ERROR,
  payload: { message }
});


export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history }
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message }
});

export const resetPassword = ({resetPasswordCode,newPassword,history}) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode,newPassword,history }
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message }
});



export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history }
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
})

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history }
});
export const updateCoin = (coin) => ({
  type: UPDATE_COIN,
  payload: { coin }
});