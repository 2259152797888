
import { all, call, fork, put, takeEvery, take } from 'redux-saga/effects';
import {eventChannel} from 'redux-saga'
import firebase from 'firebase/app';
import { auth, database } from '../../helpers/Firebase';
import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    FACEBOOK_LOGIN_USER,
    GOOGLE_LOGIN_USER,
    APPLE_LOGIN_USER
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError,
    facebookLoginUserSuccess,
    facebookLoginUserError,
    googleLoginUserSuccess,
    googleLoginUserError,
    appleLoginUserSuccess,
    appleLoginUserError
} from './actions';


export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
    await auth.signInWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);



function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        if (!loginUser.message) {
            localStorage.setItem('user_id', loginUser.user.uid);
            const channel = new eventChannel(emiter => {
                const userInfo = database.ref('users/');
                const listener =  userInfo.orderByChild("id").equalTo(loginUser.user.uid).on("value", function (data) {
                    localStorage.setItem('user_info',JSON.stringify(data.val()[loginUser.user.uid]));
                    emiter({ data: data.val()[loginUser.user.uid] || {} });
                 })
                return () => {
                  listener.off();
                };
            });
            while (true) {
                const {data} = yield take(channel);
                yield put(loginUserSuccess(loginUser.user));
                history.push('/');
            }
            
        } else {
            yield put(loginUserError(loginUser.message));
        }
    } catch (error) {
        yield put(loginUserError(error));

    }
}


export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
    await auth.createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

function* registerWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
        if (!registerUser.message) {
            localStorage.setItem('user_id', registerUser.user.uid);
            yield put(registerUserSuccess(registerUser));
            history.push('/')
        } else {
            yield put(registerUserError(registerUser.message));

        }
    } catch (error) {
        yield put(registerUserError(error));
    }
}



export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    await auth.signOut().then(authUser => authUser).catch(error => error);
    history.push('/')
}

function* logout({ payload }) {
    const { history } = payload
    try {
        yield call(logoutAsync, history);
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_info');
    } catch (error) {
    }
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
    return await auth.sendPasswordResetEmail(email)
        .then(user => user)
        .catch(error => error);
}

function* forgotPassword({ payload }) {
    const { email } = payload.forgotUserMail;
    try {
        const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
        if (!forgotPasswordStatus) {
            yield put(forgotPasswordSuccess("success"));
        } else {
            yield put(forgotPasswordError(forgotPasswordStatus.message));
        }
    } catch (error) {
        yield put(forgotPasswordError(error));

    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
    return await auth.confirmPasswordReset(resetPasswordCode, newPassword)
        .then(user => user)
        .catch(error => error);
}

function* resetPassword({ payload }) {
    const { newPassword, resetPasswordCode } = payload;
    try {
        const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
        if (!resetPasswordStatus) {
            yield put(resetPasswordSuccess("success"));
        } else {
            yield put(resetPasswordError(resetPasswordStatus.message));
        }
    } catch (error) {
        yield put(resetPasswordError(error));

    }
}

export function* watchFacebookLoginUser() {
    yield takeEvery(FACEBOOK_LOGIN_USER, loginWithFacebook);
}

const loginWithProviderAsync = async (provider) =>
    await auth.signInWithPopup(provider)
        .then(authUser => authUser)
        .catch(error => error);



function* loginWithFacebook({ payload }) {
    const { history } = payload;
    try {
        const provider = new firebase.auth.FacebookAuthProvider();
        // provider.addScope('user_birthday');
        provider.setCustomParameters({
            'display': 'popup'
        });
        const loginUser = yield call(loginWithProviderAsync, provider);
        console.log(loginUser);
        if (!loginUser.message) {
            localStorage.setItem('user_id', loginUser.user.uid);
            if(loginUser.additionalUserInfo.isNewUser === true){
                database.ref('users/'+loginUser.user.uid).set({
                    bio: '',
                    email: loginUser.additionalUserInfo.profile.email === undefined ? '' : loginUser.additionalUserInfo.profile.email,
                    id: loginUser.user.uid,
                    name: loginUser.additionalUserInfo.profile.name,
                    password: loginUser.user.uid,
                    profileImgURL: loginUser.additionalUserInfo.profile.picture.data.url,
                    title:'',
                    facebookId:loginUser.additionalUserInfo.profile.id
                })
            }
            const channel = new eventChannel(emiter => {
                const userInfo = database.ref('users/');
                const listener =  userInfo.orderByChild("id").equalTo(loginUser.user.uid).on("value", function (data) {
                    localStorage.setItem('user_info',JSON.stringify(data.val()[loginUser.user.uid]));
                    emiter({ data: data.val()[loginUser.user.uid] || {} });
                 })
                return () => {
                  listener.off();
                };
            });
            while (true) {
                const {data} = yield take(channel);
                yield put(facebookLoginUserSuccess(loginUser.user));
                history.push('/');
            }
        } else {
            yield put(facebookLoginUserError(loginUser.message));
        }
    } catch (error) {
        yield put(facebookLoginUserError(error));

    }
}

export function* watchGoogleLoginUser() {
    yield takeEvery(GOOGLE_LOGIN_USER, loginWithGoogle);
}

function* loginWithGoogle({ payload }) {
    const { history } = payload;
    try {
        const provider = new firebase.auth.GoogleAuthProvider();
        const loginUser = yield call(loginWithProviderAsync, provider);
        if (!loginUser.message) {
            localStorage.setItem('user_id', loginUser.user.uid);
            if(loginUser.additionalUserInfo.isNewUser === true){
                database.ref('users/'+loginUser.user.uid).set({
                    bio: '',
                    email: loginUser.additionalUserInfo.profile.email,
                    id: loginUser.user.uid,
                    name: loginUser.additionalUserInfo.profile.name,
                    password: loginUser.user.uid,
                    profileImgURL: loginUser.additionalUserInfo.profile.picture,
                    title:'',
                    googleId:loginUser.additionalUserInfo.profile.id
                })
            }
            
            const channel = new eventChannel(emiter => {
                const userInfo = database.ref('users/');
                const listener =  userInfo.orderByChild("id").equalTo(loginUser.user.uid).on("value", function (data) {
                    localStorage.setItem('user_info',JSON.stringify(data.val()[loginUser.user.uid]));
                    emiter({ data: data.val()[loginUser.user.uid] || {} });
                 })
                return () => {
                  listener.off();
                };
            });
            while (true) {
                const {data} = yield take(channel);
                yield put(googleLoginUserSuccess(loginUser.user));
                history.push('/');
            }
        } else {
            yield put(googleLoginUserError(loginUser.message));
        }
    } catch (error) {
        yield put(googleLoginUserError(error));

    }
}

export function* watchAppleLoginUser() {
    yield takeEvery(APPLE_LOGIN_USER, loginWithApple);
}

function* loginWithApple({ payload }) {
    const { history } = payload;
    try {
        const provider = new firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        const loginUser = yield call(loginWithProviderAsync, provider);
        console.log(loginUser);
        if (!loginUser.message) {
            localStorage.setItem('user_id', loginUser.user.uid);
            if(loginUser.additionalUserInfo.isNewUser === true){
                database.ref('users/'+loginUser.user.uid).set({
                    bio: '',
                    email: loginUser.additionalUserInfo.profile.email,
                    id: loginUser.user.uid,
                    name: loginUser.additionalUserInfo.profile.name === undefined ? 'User' : loginUser.additionalUserInfo.profile.name,
                    password: loginUser.user.uid,
                    profileImgURL: '',
                    title:'',
                    appleId:'apple'
                })
            }
            
            const channel = new eventChannel(emiter => {
                const userInfo = database.ref('users/');
                const listener =  userInfo.orderByChild("id").equalTo(loginUser.user.uid).on("value", function (data) {
                    localStorage.setItem('user_info',JSON.stringify(data.val()[loginUser.user.uid]));
                    emiter({ data: data.val()[loginUser.user.uid] || {} });
                 })
                return () => {
                  listener.off();
                };
            });
            while (true) {
                const {data} = yield take(channel);
                yield put(appleLoginUserSuccess(loginUser.user));
                history.push('/');
            }
        } else {
            yield put(appleLoginUserError(loginUser.message));
        }
    } catch (error) {
        yield put(appleLoginUserError(error));

    }
}
export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
        fork(watchFacebookLoginUser),
        fork(watchGoogleLoginUser),
        fork(watchAppleLoginUser)
    ]);
}