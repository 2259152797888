/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "es", name: "Español", direction: "ltr" },
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" }
];

export const firebaseConfig = {
  apiKey: "AIzaSyDBLVD_sBccR-nxjla9qAqIBA-1OQTpO8w",
  // authDomain: "cephalopod-ebcf1.firebaseapp.com",
  authDomain: "bitcade.app",
  databaseURL: "https://cephalopod-ebcf1.firebaseio.com",
  projectId: "cephalopod-ebcf1",
  storageBucket: "gs://cephalopod-ebcf1.appspot.com",
  // messagingSenderId: "216495999563"
};
// export const firebaseConfig = {
//   apiKey: "AIzaSyDMOwZsM_E_x1GyHfDJehJIA6VaN8hziNw",
//   authDomain: "spark-aea6e.firebaseapp.com",
//   databaseURL: "https://spark-aea6e-default-rtdb.firebaseio.com/",
//   projectId: "spark-aea6e",
//   // storageBucket: "gogo-react-login.appspot.com",
//   // messagingSenderId: "216495999563"
// };

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = false;
export const defaultColor = "light.blue";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = true;
export const themeColorStorageKey="__theme_color";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;